export const VIOLATION_TYPES = [
  "BC",
  "RC",
  "YC",
  "CG",
  "CM",
  "CS",
  "CW",
  "W",
  "TW",
  "PL",
  "GL",
  "SL",
  "D",
  "VW",
  "TW",
  "TF",
];

export const TYPES = {
  ACE: "Ace",

  BC: "Black card",
  RC: "Red card",
  YC: "Yellow card",

  CW: "Conduct warning",
  CS: "Conduct stroke",
  CG: "Conduct game",
  CM: "Conduct match",

  W: "Warning",
  PL: "Point loss",
  GL: "Game loss",
  SL: "Service loss",
  D: "Disqualification",

  TO: "Timeout",
  INJ: "Injury",
  MTO: "Medical timeout",
  WC: "Toilet visit",
  OR: "Overrule linesman",
  REF: "Referee called",
  RET: "Retire",
  WLK: "Walkover",
  RP: "Replay point",
  S: "Suspend",
  SEC: "Second serve",
  YL: "Let",
  NL: "No let",
  ST: "Stroke",
  NT: "Note",

  VW: "Verbal warning",
  TW: "Technical warning",
  TF: "Technical foul",
  // FG: 'Forfeit game',
  // FM: 'Forfeit match',
};

export const RETIREMENT_CODES = [
  "RET", // Retirement
  "WLK", // Walk over
  "BC", // Black card
  "D", // Disqualification
  "CM", // Conduct match
];

export const LINES = ["LB", "LF", "LC", "LN", "S", "RB", "RF", "RC", "RN"];

export const CONNECTION_STATUSES = {
  unknown: { color: "blue", text: "Connect" },
  connected: { color: "green", icon: "far fa-check" },
  disconnected: { color: "red", icon: "far fa-exclamation-triangle" },
};
export const API_HOST = process.env.API_HOST;
export const WEBSOCKET_HOST = process.env.WEBSOCKET_HOST;
export const DB = process.env.DB || "db";
export const LOGO = `/static/${process.env.LOGO}`;
export const SPORTS = process.env.SPORTS;
export const APP_NAME = process.env.APP_NAME;
export const VERSION_CHECK = process.env.VERSION_CHECK;
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID;
export const APPLE_APPSTORE_PRODUCTS =
  process.env.APPLE_APPSTORE_PRODUCTS.split(",");
export const GOOGLE_PLAY_PRODUCTS = process.env.GOOGLE_PLAY_PRODUCTS.split(",");
export const DOMAIN = process.env.DOMAIN;
export const LANGUAGES = process.env.LANGUAGES.split(" ");
export const DEFAULT_LANGUAGE =
  process.env.DEFAULT_LANGUAGE ||
  navigator.language.substr(0, 2).toLowerCase() ||
  "en";
export const DEVELOPMENT = process.env.NODE_ENV === "development";
